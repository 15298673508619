import React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import {useLocation} from "react-router";
import { Link }from "react-router-dom"


export default function Test() {
    const [posts, setPosts] = useState([]);
    const { search } = useLocation();

    const [iterator, setIterator] = useState(null);
    const [endingLink, setEndingLink] = useState(null);
    const [page, setPage] = useState(null);
    const [numberOfPages, setNumberOfPages] = useState(null);


    useEffect(()=>{
        const fetchPosts = async ()=>{
            try{
            const res = await axios.get("/tests/" + search);
            console.log(res);
            setPosts(res.data.data);
            setIterator(res.data.iterator || null);
            setEndingLink(res.data.endingLink || null);
            setPage(res.data.page || null);
            setNumberOfPages(res.data.numberOfPages || null);
            }catch(err){
            console.log(err);
            }
        };

        fetchPosts();
    }, [search])

   console.log({iterator, endingLink, page, numberOfPages})

  /* const pagination =  ()=>{
        const arr = [];

        if(page > 1){
            arr.push(<Link to={`/test/?page=${page-1}`}>prev</Link>)
        }

        for(let i = iterator; i <= endingLink; i++){
                arr.push(<Link to={`/test/?page=${i}`}>{i}</Link>)
        }

        if(page < numberOfPages){
            arr.push(<Link to={`/test/?page=${page+1}`}>next</Link>)
        }

        return arr;
    } */
                
    const pageNumbers = [];

    if(page > 1){
        pageNumbers.push("prev");
    }

    for(let i = iterator; i <= endingLink; i++){
        pageNumbers.push(i);
    }

    if(page < numberOfPages){
        pageNumbers.push("next");
    }

    return (
        <div>
            {posts.map((post)=>{
                return (
                    <div key={post.id}>
                        <h3>{post.title}</h3>
                        <p>{post.desc}</p>
                    </div>
                )
            })}

            {/*pagination()*/}

            <div>
                {pageNumbers.map((number)=>{
                    return (
                    <Link to={number === "prev" ? `/test/?page=${page-1}` : 
                            (number === "next" ? `/test/?page=${page+1}` : `/test/?page=${number}`)}>
                        {number}
                    </Link>
                    )
                })}
            </div>
        
        </div>
    )
}

